






















import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Ref } from 'vue-property-decorator'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import Tag from '@/components/_uikit/Tag.vue'
import UserCard from '@/components/cards/UserCard.vue'
// store
import ManagerUsersModule from '@/store/modules/manager/users'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component({
  components: {
    Confirmation,
    LinkTabs,
    Tag,
    UserCard,
  },
})
export default class EmployeesItem extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private isLoaded = false

  private get breadcrumbs () {
    return [
      { name: 'Сотрудники', path: '/manager/users/employees' },
    ]
  }

  private get tabs () {
    return [{
      name: 'Данные и права',
      to: {
        name: 'manager.users.employees.item',
        params: { userID: this.user?.uuid },
      },
    } ]
  }

  private get user () {
    return ManagerUsersModule.user
  }

  private created () {
    this.fetchUser()
  }

  private fetchUser () {
    ManagerUsersModule.fetchUser(this.$route.params.employeeUUID)
      .then(() => {
        this.isLoaded = true
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.employees.users' })
      })
  }

  private confirmDelete () {
    this.confirm.open(
      'Удаление пользователя',
      'Вы уверены, что хотите удалить пользователя? Обратите внимание: e-mail удаленного аккаунта нельзя будет использовать в системе повторно.',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.deleteUser)
      .catch(() => {return})
  }

  private deleteUser() {
    ManagerUsersModule.deleteUser(this.user?.uuid as string)
      .then(() => {
        this.notifySuccess(`Пользователь ${this.user?.name} ${this.user?.surname} удалён`)
        this.$router.push({ name: 'manager.users.employees' })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: `Сотрудник ${this.user?.name} ${this.user?.surname}`,
    }
  }
}
